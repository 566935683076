import React from 'react';
import {Box, Container, Grid, GridItem, Link} from "@chakra-ui/react";
import {FormattedMessage} from "react-intl";

export const PageFooter = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const showLegalLinks = false;

    let showAffiliateSignup = false;

    if (urlParams.has('affiliate') && urlParams.get('affiliate') === '19') {
        showAffiliateSignup = true;
    }

    return (
        <>
            {showLegalLinks &&
                <Box id={"footer"} mt={10} borderTop="2px" fontSize="xs" borderColor="gray.200">
                    <Container className="main-contianer" p={5} maxW="container.lg">
                        <Grid templateColumns='repeat(2, 1fr)' gap={1}>
                            <GridItem w='100%'>
                                <Link target="_blank" href="/impressum.html">
                                    <FormattedMessage id="footer.imprint"/>
                                </Link>
                            </GridItem>
                            <GridItem w='100%' textAlign={'right'}>
                                <Link target="_blank" href="/privacy.html">
                                    <FormattedMessage id="footer.privacy"/>
                                </Link>

                                {showAffiliateSignup &&
                                    <Link ml={5} target="_blank" href="https://t.me/PromoteFIGUR">
                                        Affiliates
                                    </Link>
                                }
                            </GridItem>
                        </Grid>
                    </Container>
                </Box>
            }

            {showAffiliateSignup === true && showLegalLinks === false &&
                <Box id={"footer"} mt={10} borderTop="2px" fontSize="xs" borderColor="gray.200">
                    <Container className="main-contianer" p={5} maxW="container.lg" textAlign={'right'}>
                        <Link target="_blank" href="https://blitzadsmedia.com/LIBA/index.html">
                            Affiliates
                        </Link>
                    </Container>
                </Box>
            }
        </>
    )
}
