import './App.scss';
import {ChakraProvider, extendTheme} from '@chakra-ui/react'
import {Stepper} from './comnponents/Stepper';
import ReactGA from 'react-ga';
import {IntlProvider} from "react-intl";
import English from './lang/en.json';
import moment from 'moment';
import 'moment/locale/en-gb';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/700.css';

let messages = English;
let locale = 'en';
moment.locale('en');

/*
switch (true) {
    case (currentUrl.search('/uk') !== -1):
        messages = English;
        messages.outgoingUrl = 'https://lb-capsules-shop.com/uk/order-now.html';
        locale = 'en';
        moment.locale('en');
        break;


    case (currentUrl.search('/ie') !== -1):
        messages = EnglishIrish;
        messages.outgoingUrl = 'https://lb-capsules-shop.com/ie/order-now.html';
        locale = 'en-ie';
        moment.locale('en');
        break;

    case (currentUrl.search('/fr') !== -1):
        messages = French;
        locale = 'fr';
        moment.locale('fr');
        break;

    case (currentUrl.search('/at') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://lb-capsules.com/at/jetzt-kaufen.html';
        locale = 'de';
        break;

    case (currentUrl.search('/ch') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://lb-capsules-shop.com/ch/jetzt-kaufen.html';
        locale = 'de';
        break;

    case (currentUrl.search('/nl') !== -1):
        messages = Dutch;
        locale = 'nl';
        moment.locale('nl');
        break;
    default:
        locale = 'de';
}
*/

const theme = extendTheme({
    fonts: {
        heading: "'Poppins', 'sans-serif'",
        body: "'Poppins', 'sans-serif'",
    }
})

document.title = 'Lean Boost® - ' + messages.title;

function App() {
    ReactGA.initialize('UA-183263461-14');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="App">
            <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                <ChakraProvider theme={theme}>
                    <Stepper/>
                </ChakraProvider>
            </IntlProvider>
        </div>
    );
}

export default App;
